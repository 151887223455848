import { FlightAddBookingDispatch } from '../reducers/TBOReducer';
import { setAddBookingData } from '../reducers/UiReducer';
import { SHA512 } from 'crypto-js';
import { fetchInitiatePayment } from '../reducers/HomeReducer';

const addTBODomRoundtripBooking = (
	location,
	TBOPAONWARD,
	TBOPARETURN,
	pay,
	farequote,
	farequoteReturn,
	BookingFormData,
	dispatch,
	navigate,
	agentInfo
) => {
	const FareQuotePrice = Number(
		Number(
			Math.round(farequote?.result?.Response?.Results?.Fare.Total_Amount)
		) +
			Number(
				Math.round(
					farequoteReturn?.result?.Response?.Results?.Fare.Total_Amount
				)
			)
	);
	const OriginalPrice = Number(
		Number(Math.round(location.state.onwardFlight.Fare.Total_Amount)) +
			Number(Number(Math.round(location.state.returnFlight.Fare.Total_Amount)))
	);
	function generateSHA512Hash(data) {
		const hash = SHA512(data);
		return hash.toString();
	}
	const payableAmout =
		Number(Number(TBOPAONWARD) + Number(TBOPARETURN)) +
		Number((Number(Number(TBOPAONWARD) + Number(TBOPARETURN)) * 3.5) / 100);
	let formAddTBOBooking = {
		orderId: location.state.orderId,
		supplier: location.state.onwardFlight.Supplier,
		userEmail: location.state.passengerData.email,
		userPhone: location.state.passengerData.phone,
		user_type: agentInfo?.status === 200 ? 'AGENT' : 'GUEST',
		origin: location.state.formData.Segments[0].Origin,
		destination: location.state.formData.Segments[0].Destination,
		travelDate: location.state.formData.Segments[0].PreferredDepartureTime,
		returnDate: location.state.formData.Segments[1].PreferredDepartureTime,
		journeyType: location.state.formData.JourneyType === 2 ? 'ROUNDTRIP' : '',
		origin1: location.state.formData.Segments[1].Origin,
		destination1: location.state.formData.Segments[1].Destination,
		travelDate1: '',
		origin2: '',
		destination2: '',
		travelDate2: '',
		origin3: '',
		destination3: '',
		travelDate3: '',
		PGCharge:
			pay === 'ONLINE'
				? Number(
						(Number(Number(TBOPAONWARD) + Number(TBOPARETURN)) * 3.5) / 100
				  )
				: 0,
		class: 'Economy',
		nop: `${location.state.formData.AdultCount} | ${location.state.formData.ChildCount} | ${location.state.formData.InfantCount} `,
		airlineType: '',
		carrier: `${location.state.onwardFlight.AirlineCode}|${location.state.returnFlight.AirlineCode}`,
		fareType: location.state.onwardFlight.IsRefundable ? 'R' : 'N',
		eTicketType: 'eTicket',
		actualFare: Number(
			Number(Math.round(location.state.onwardFlight.Fare.Total_Amount)) -
				Number(Math.round(location.state.onwardFlight.Fare.partnerMarkup)) -
				Number(Math.round(location.state.onwardFlight.Fare.agentMarkup)) -
				Number(Math.round(location.state.onwardFlight.Fare.markup)) -
				Number(Math.round(location.state.onwardFlight.Fare.MFB_Discount)) +
				Number(Math.round(location.state.returnFlight.Fare.Total_Amount)) -
				Number(Math.round(location.state.returnFlight.Fare.partnerMarkup)) -
				Number(Math.round(location.state.returnFlight.Fare.agentMarkup)) -
				Number(Math.round(location.state.onwardFlight.Fare.markup)) -
				Number(Math.round(location.state.returnFlight.Fare.MFB_Discount))
		),
		markup:
			Number(Math.round(location.state.onwardFlight.Fare.markup)) +
			Number(Math.round(location.state.returnFlight.Fare.markup)),
		baseFare: Number(
			Number(Math.round(location.state.onwardFlight.Fare.Total_Amount)) -
				Number(Math.round(location.state.onwardFlight.Fare.partnerMarkup)) -
				Number(Math.round(location.state.onwardFlight.Fare.agentMarkup)) -
				Number(Math.round(location.state.onwardFlight.Fare.MFB_Discount)) +
				Number(Math.round(location.state.returnFlight.Fare.Total_Amount)) -
				Number(Math.round(location.state.returnFlight.Fare.partnerMarkup)) -
				Number(Math.round(location.state.returnFlight.Fare.agentMarkup)) -
				Number(Math.round(location.state.returnFlight.Fare.MFB_Discount))
		),
		partnerMarkup: Number(
			Number(Math.round(location.state.onwardFlight.Fare.partnerMarkup)) +
				Number(Math.round(location.state.returnFlight.Fare.partnerMarkup))
		),
		agentMarkup: Number(
			Number(Math.round(location.state.onwardFlight.Fare.agentMarkup)) +
				Number(Math.round(location.state.returnFlight.Fare.agentMarkup))
		),
		totalFare: Number(Number(TBOPAONWARD) + Number(TBOPARETURN)),
		payableAmount:
			pay === 'ONLINE'
				? Number(payableAmout)
				: Number(payableAmout) -
				  Number(
						Number(
							(Number(Number(TBOPAONWARD) + Number(TBOPARETURN)) * 3.5) / 100
						)
				  ),
		ticketCost:
			pay === 'ONLINE'
				? Number(payableAmout) +
				  Number(
						Number(Math.round(location.state.onwardFlight.Fare.agentMarkup)) +
							Number(Math.round(location.state.returnFlight.Fare.agentMarkup))
				  )
				: Number(payableAmout) -
				  Number(
						Number(
							(Number(Number(TBOPAONWARD) + Number(TBOPARETURN)) * 3.5) / 100
						)
				  ),
		totalEarn: 0,
		cashBack: '0.00',
		couponDiscount: 0.0,
		couponDetails: '',
		pricingDetails: [
			farequote?.result?.Response?.Results,
			farequoteReturn?.result?.Response?.Results,
		],
		selectedFlight: [location.state.onwardFlight, location.state.returnFlight],
		airlineType: `${location.state.onwardFlight.IsLCC ? 'LCC' : 'GDS'}|${
			location.state.returnFlight.IsLCC ? 'LCC' : 'GDS'
		}`,
		selectedPax: BookingFormData,
		availableOnNewPrice:
			Number(FareQuotePrice) > Number(OriginalPrice)
				? true
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? true
				: false,
		priceChange: Number(
			Number(Math.round(FareQuotePrice)) - Number(Math.round(OriginalPrice))
		),
		priceValue:
			Number(Math.round(FareQuotePrice)) > Number(Math.round(OriginalPrice))
				? 'PRICEUP'
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? 'PRICEDOWN'
				: 'NOCHANGE',
		ticketSent: 0,
		paymentMode: pay === 'ONLINE' ? 'ONLINE' : 'OFFLINE',
		agent_id: agentInfo?.status === 200 ? agentInfo.result.result.id : 0,
	};
	dispatch(setAddBookingData(formAddTBOBooking));
	dispatch(FlightAddBookingDispatch(formAddTBOBooking, navigate));
	console.log('add booking', formAddTBOBooking);
	let details = {
		// key: 'DO2J1JSRU2',
		// txnid: location.state.orderId,
		// amount: `${Math.round(Number(payableAmout))}.00`,
		// productinfo: 'FLIGHT',
		// firstname: BookingFormData[0]?.firstname,
		// phone: location.state.passengerData.phone,
		// email: location.state.passengerData.email,
		// surl: 'https://www.marinersforex.com/',
		// furl: 'https://www.marinersforex.com/',
		// hash: generateSHA512Hash(
		// 	`DO2J1JSRU2|${location.state.orderId}|${Math.round(
		// 		Number(payableAmout)
		// 	)}.00|FLIGHT|${BookingFormData[0]?.firstname}|${
		// 		location.state.passengerData.email
		// 	}|||||||||||WUBF6O2X3M`
		// ),
		orderId: location.state.orderId,
		payableAmount: Math.round(Number(payableAmout)),
		firstname: BookingFormData[0]?.firstname,
		phone: location.state.passengerData.phone,
		email: location.state.passengerData.email,
	};
	let detailsTest = {
		key: '2PBP7IABZ2',
		txnid: location.state.orderId,
		amount: `${Math.round(Number(payableAmout))}.00`,
		productinfo: 'FLIGHT',
		firstname: BookingFormData[0]?.firstname,
		phone: location.state.passengerData.phone,
		email: location.state.passengerData.email,
		surl: 'https://www.marinersforex.com/',
		furl: 'https://www.marinersforex.com/',
		hash: generateSHA512Hash(
			`2PBP7IABZ2|${location.state.orderId}|${Math.round(
				Number(payableAmout)
			)}.00|FLIGHT|${BookingFormData[0]?.firstname}|${
				location.state.passengerData.email
			}|||||||||||DAH88E3UWQ`
		),
	};
	var formBody = [];
	for (var property in details) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(details[property]);
		formBody.push(encodedKey + '=' + encodedValue);
	}
	formBody = formBody.join('&');

	console.log('formBody', formBody);
	dispatch(fetchInitiatePayment(details));
};
export default addTBODomRoundtripBooking;
