import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModalEasebuzzBus } from '../../reducers/UiReducer';
import EaseBuzzFormBus from '../../forms/EaseBuzzFormBus';
const PaymentEaseBuzzBus = ({ pay, pgCharge, handleBook }) => {
	const [loading, setLoading] = useState(false);
	const { paymentToggleeasebuzzBus } = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	const handleOk = () => {
		// setLoading(true);
		// handleBook(setLoading);
	};
	const handleCancel = () => {
		dispatch(setModalEasebuzzBus(false));
	};

	return (
		<>
			<Modal
				open={paymentToggleeasebuzzBus}
				title='Confirm Booking'
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel} disabled={loading}>
						Cancel
					</Button>,
				]}>
				<Divider />
				<EaseBuzzFormBus
					pay={pay}
					pgCharge={pgCharge}
					handleBook={handleBook}
				/>
			</Modal>
		</>
	);
};
export default PaymentEaseBuzzBus;
