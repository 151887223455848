import { axiosInstance } from '../Axios';
import { setSelectedArrival, setSelectedDepart } from './UiReducer';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		city: null,
		banner: null,
		tourdest: null,
		tourpackage: null,
		footerMenu: null,
		tourdestdom: null,
		general: null,
		dom: null,
		int: null,
		seo: null,
		payment: null,
	},
	reducers: {
		cityRequest(state, action) {
			// state.loading = true;
		},
		citySuccess(state, action) {
			// state.loading = false;
			state.city = action.payload;
			state.error = null;
		},
		cityFail(state, action) {
			// state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.banner = action.payload;
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourdestRequest(state, action) {
			state.loading = true;
		},
		tourdestSuccess(state, action) {
			state.loading = false;
			state.tourdest = action.payload;
			state.error = null;
		},
		tourdestFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourdestdomRequest(state, action) {
			state.loading = true;
		},
		tourdestdomSuccess(state, action) {
			state.loading = false;
			state.tourdestdom = action.payload;
			state.error = null;
		},
		tourdestdomFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourpackageRequest(state, action) {
			state.loading = true;
		},
		tourpackageSuccess(state, action) {
			state.loading = false;
			state.tourpackage = action.payload;
			state.error = null;
		},
		tourpackageFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		footerMenuRequest(state, action) {
			state.loading = true;
		},
		footerMenuSuccess(state, action) {
			state.loading = false;
			state.footerMenu = action.payload;
			state.error = null;
		},
		footerMenuFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		generalRequest(state, action) {
			state.loading = true;
		},
		generalSuccess(state, action) {
			state.loading = false;
			state.general = action.payload;
			state.error = null;
		},
		generalFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		domRequest(state, action) {
			state.loading = true;
		},
		domSuccess(state, action) {
			state.loading = false;
			state.dom = action.payload;
			state.error = null;
		},
		domFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		intRequest(state, action) {
			state.loading = true;
		},
		intSuccess(state, action) {
			state.loading = false;
			state.int = action.payload;
			state.error = null;
		},
		intFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		seoRequest(state, action) {
			state.loading = true;
		},
		seoSuccess(state, action) {
			state.loading = false;
			state.seo = action.payload;
			state.error = null;
		},
		seoFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		initiatePaymentRequest(state, action) {
			state.loading = true;
		},
		initiatePaymentSuccess(state, action) {
			state.loading = false;
			state.payment = action.payload;
			state.error = null;
		},
		initiatePaymentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjA4MzFiNDBkZmI1NQ==',
	},
};
export const {
	cityRequest,
	citySuccess,
	cityFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	tourdestRequest,
	tourdestSuccess,
	tourdestFail,
	tourpackageRequest,
	tourpackageSuccess,
	tourpackageFail,
	footerMenuRequest,
	footerMenuSuccess,
	footerMenuFail,
	tourdestdomRequest,
	tourdestdomSuccess,
	tourdestdomFail,
	generalRequest,
	generalSuccess,
	generalFail,
	domRequest,
	domSuccess,
	domFail,
	intRequest,
	intSuccess,
	intFail,
	seoRequest,
	seoSuccess,
	seoFail,
	initiatePaymentRequest,
	initiatePaymentSuccess,
	initiatePaymentFail,
} = actions;

export const cityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityRequest());
		const { data } = await axiosInstance.post(
			'/home/flightairport',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(citySuccess(data));
		if (bodyData.limits === 8) {
			dispatch(setSelectedDepart(data?.result[1]));
			dispatch(setSelectedArrival(data?.result[3]));
		}
	} catch (error) {
		dispatch(
			cityFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(bannerRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/banner',
			config
		);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtourdestDispatch = () => async (dispatch) => {
	try {
		dispatch(tourdestRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/tourdest',
			config
		);
		console.log('data', data);
		dispatch(tourdestSuccess(data));
	} catch (error) {
		dispatch(
			tourdestFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtourdestdomDispatch = () => async (dispatch) => {
	try {
		dispatch(tourdestdomRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/tourdestdom',
			config
		);
		console.log('data', data);
		dispatch(tourdestdomSuccess(data));
	} catch (error) {
		dispatch(
			tourdestdomFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showdomDispatch = () => async (dispatch) => {
	try {
		dispatch(domRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/dom',
			config
		);
		console.log('data', data);
		dispatch(domSuccess(data));
	} catch (error) {
		dispatch(
			domFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showintDispatch = () => async (dispatch) => {
	try {
		dispatch(intRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/int',
			config
		);
		console.log('data', data);
		dispatch(intSuccess(data));
	} catch (error) {
		dispatch(
			intFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const generalDispatch = () => async (dispatch) => {
	try {
		dispatch(generalRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/about',
			config
		);
		console.log('data', data);
		dispatch(generalSuccess(data));
	} catch (error) {
		dispatch(
			generalFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showtourpackageDispatch = () => async (dispatch) => {
	try {
		dispatch(tourpackageRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/tourpackage',
			config
		);
		console.log('data', data);
		dispatch(tourpackageSuccess(data));
	} catch (error) {
		dispatch(
			tourpackageFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showFootermenuDispatch = () => async (dispatch) => {
	try {
		dispatch(footerMenuRequest());
		const { data } = await axiosInstance.get('/home/footer_submenu', config);
		console.log('data', data);
		dispatch(footerMenuSuccess(data));
	} catch (error) {
		dispatch(
			footerMenuFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showSeoDispatch = () => async (dispatch) => {
	try {
		dispatch(seoRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/seo',
			config
		);
		console.log('data', data);
		dispatch(seoSuccess(data));
	} catch (error) {
		dispatch(
			seoFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchInitiatePayment = (bodyData) => async (dispatch) => {
	try {
		const configpay = {
			headers: {
				Accept: 'application/json',
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
		};
		dispatch(initiatePaymentRequest());

		const { data } = await axios.post(
			'https://api.marinersforex.com/api/home/initiatePayment',
			bodyData,
			configpay
		);
		dispatch(initiatePaymentSuccess(data));
		console.log('first', data);
		if (data) {
			const key = 'DO2J1JSRU2'; // Replace with your Easebuzz key
			const environment = 'prod'; // Use "prod" for production
			const accessKey = data?.data; // Replace with your actual access key
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			initiatePaymentFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
