import { FlightAddBookingDispatch } from '../reducers/TBOReducer';
import { setAddBookingData } from '../reducers/UiReducer';
import { SHA512 } from 'crypto-js';
import { fetchInitiatePayment } from '../reducers/HomeReducer';

const addTBORoundtripBooking = (
	location,
	TBOPA,
	pay,
	farequote,
	BookingFormData,
	dispatch,
	navigate,
	agentInfo
) => {
	const FareQuotePrice = Number(
		Math.round(farequote?.result?.Response?.Results?.Fare.Total_Amount)
	);
	const OriginalPrice = Number(
		Math.round(location.state.item.Fare.Total_Amount)
	);
	function generateSHA512Hash(data) {
		const hash = SHA512(data);
		return hash.toString();
	}
	const payableAmout = Number(TBOPA) + Number((Number(TBOPA) * 3.5) / 100);
	let formAddTBOBooking = {
		orderId: location.state.orderId,
		supplier: location.state.item.Supplier,
		userEmail: location.state.passengerData.email,
		userPhone: location.state.passengerData.phone,
		user_type: agentInfo?.status === 200 ? 'AGENT' : 'GUEST',
		origin: location.state.formData.Segments[0].Origin,
		destination: location.state.formData.Segments[0].Destination,
		travelDate: location.state.formData.Segments[0].PreferredDepartureTime,
		returnDate: location.state.formData.Segments[1].PreferredDepartureTime,
		journeyType: location.state.formData.JourneyType === 2 ? 'ROUNDTRIP' : '',
		origin1: location.state.formData.Segments[1].Origin,
		destination1: location.state.formData.Segments[1].Destination,
		travelDate1: '',
		origin2: '',
		destination2: '',
		travelDate2: '',
		origin3: '',
		destination3: '',
		travelDate3: '',
		PGCharge: pay === 'ONLINE' ? Number((Number(TBOPA) * 3.5) / 100) : 0,
		class: 'Economy',
		nop: `${location.state.formData.AdultCount} | ${location.state.formData.ChildCount} | ${location.state.formData.InfantCount} `,
		airlineType: '',
		carrier: location.state.item.AirlineCode,
		fareType: location.state.item.IsRefundable ? 'R' : 'N',
		eTicketType: 'eTicket',
		actualFare:
			Number(Math.round(location.state.item.Fare.Total_Amount)) -
			Number(Math.round(location.state.item.Fare.markup)) -
			Number(Math.round(location.state.item.Fare.partnerMarkup)) -
			Number(Math.round(location.state.item.Fare.agentMarkup)) -
			Number(Math.round(location.state.item.Fare.MFB_Discount)),
		markup: Math.round(location.state.item.Fare.markup),
		baseFare:
			Number(Math.round(location.state.item.Fare.Total_Amount)) -
			Number(Math.round(location.state.item.Fare.partnerMarkup)) -
			Number(Math.round(location.state.item.Fare.agentMarkup)) -
			Number(Math.round(location.state.item.Fare.MFB_Discount)),
		partnerMarkup: Math.round(location.state.item.Fare.partnerMarkup),
		agentMarkup: Math.round(location.state.item.Fare.agentMarkup),
		totalFare: Number(TBOPA),
		payableAmount:
			pay === 'ONLINE'
				? Number(payableAmout)
				: Number(payableAmout) - Number(Number((Number(TBOPA) * 3.5) / 100)),
		ticketCost:
			pay === 'ONLINE'
				? Number(payableAmout) +
				  Number(Math.round(location.state.item.Fare.agentMarkup))
				: Number(payableAmout) - Number(Number((Number(TBOPA) * 3.5) / 100)),
		totalEarn: 0,
		cashBack: '0.00',
		couponDiscount: 0.0,
		couponDetails: '',
		pricingDetails: farequote?.result?.Response?.Results,
		selectedFlight: location.state.item,
		airlineType: location.state.item.IsLCC ? 'LCC' : 'GDS',
		selectedPax: BookingFormData,
		availableOnNewPrice:
			Number(FareQuotePrice) > Number(OriginalPrice)
				? true
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? true
				: false,
		priceChange: Number(
			Number(Math.round(FareQuotePrice)) - Number(Math.round(OriginalPrice))
		),
		priceValue:
			Number(Math.round(FareQuotePrice)) > Number(Math.round(OriginalPrice))
				? 'PRICEUP'
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? 'PRICEDOWN'
				: 'NOCHANGE',
		ticketSent: 0,
		paymentMode: pay === 'ONLINE' ? 'ONLINE' : 'OFFLINE',
		agent_id: agentInfo?.status === 200 ? agentInfo.result.result.id : 0,
	};
	dispatch(FlightAddBookingDispatch(formAddTBOBooking, navigate));
	dispatch(setAddBookingData(formAddTBOBooking));
	let details = {
		// key: 'DO2J1JSRU2',
		// txnid: location.state.orderId,
		// amount: `${Math.round(Number(payableAmout))}.00`,
		// productinfo: 'FLIGHT',
		// firstname: BookingFormData[0]?.firstname,
		// phone: location.state.passengerData.phone,
		// email: location.state.passengerData.email,
		// surl: 'https://www.marinersforex.com/',
		// furl: 'https://www.marinersforex.com/',
		// hash: generateSHA512Hash(
		// 	`DO2J1JSRU2|${location.state.orderId}|${Math.round(
		// 		Number(payableAmout)
		// 	)}.00|FLIGHT|${BookingFormData[0]?.firstname}|${
		// 		location.state.passengerData.email
		// 	}|||||||||||WUBF6O2X3M`
		// ),
		orderId: location.state.orderId,
		payableAmount: Math.round(Number(payableAmout)),
		firstname: BookingFormData[0]?.firstname,
		phone: location.state.passengerData.phone,
		email: location.state.passengerData.email,
	};
	let detailsTest = {
		key: '2PBP7IABZ2',
		txnid: location.state.orderId,
		amount: `${Math.round(Number(payableAmout))}.00`,
		productinfo: 'FLIGHT',
		firstname: BookingFormData[0]?.firstname,
		phone: location.state.passengerData.phone,
		email: location.state.passengerData.email,
		surl: 'https://www.marinersforex.com/',
		furl: 'https://www.marinersforex.com/',
		hash: generateSHA512Hash(
			`2PBP7IABZ2|${location.state.orderId}|${Math.round(
				Number(payableAmout)
			)}.00|FLIGHT|${BookingFormData[0]?.firstname}|${
				location.state.passengerData.email
			}|||||||||||DAH88E3UWQ`
		),
	};
	var formBody = [];
	for (var property in details) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(details[property]);
		formBody.push(encodedKey + '=' + encodedValue);
	}
	formBody = formBody.join('&');

	console.log('formBody', formBody);
	dispatch(fetchInitiatePayment(details));
	// console.log('add booking', formAddTBOBooking);
};
export default addTBORoundtripBooking;
