import React, { useEffect, useState } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import {
	Grid,
	Box,
	Button,
	Container,
	useTheme,
	useMediaQuery,
	TextField,
} from '@mui/material';
import loc from '../assets/images/loctions.svg';
import dayjs from 'dayjs';
import calender from '../assets/images/calender.svg';
import locwhite from '../assets/images/locwhite.svg';
import calwhite from '../assets/images/calwhite.svg';
import travwhite from '../assets/images/travwhite.svg';
import Popover from '@mui/material/Popover';
import flightlogo from '../assets/images/flightlogo.svg';
import Guest from '../assets/images/Guest.svg';
import search from '../assets/images/search.svg';
import tick from '../assets/images/tick.svg';
import { DatePicker } from 'antd';
import FilterSectionHotel from '../components/resuableComponents/FilterSectionHotel';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	addhotelSearchDispatch,
	fetchHotelCityDispatch,
} from '../reducers/HotelReducer';
import {
	removeSelectedRoom,
	setAdultH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
	setChildH,
	setSelectedHotel,
	setSelectedRoom,
} from '../reducers/UiReducer';
import FlightRowSkeleton from '../components/resuableComponents/FlightRowSkeleton';

const dateFormat = 'YYYY-MM-DD';

const HotelResultPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [departDate, setDepartDate] = useState('');
	const [result, setResult] = useState([]);
	const [returnDate, setDepartDate2] = useState('');
	const { hotelResult, loadinghotel, hotel } = useSelector(
		(state) => state.hoteltbo
	);
	const {
		depart,
		arrival,
		hoteldes,
		adultH,
		room,
		childH,
		childAge1,
		childAge2,
		childAge3,
		childAge4,
	} = useSelector((state) => state.ui);
	const [num, setNum] = useState(1);
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const dispatch = useDispatch();
	console.log('hotelResult', hotelResult);
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const onChangeReturn = (date, dateString) => {
		setDepartDate2(dateString);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChange = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchHotelCityDispatch(cityData));
	};
	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.state) {
			dispatch(addhotelSearchDispatch(location.state));
		}
	}, [location.state]);
	console.log('Location', location);
	var date1 = new Date(departDate ? departDate : location.state.checkInDate);
	var date2 = new Date(returnDate ? returnDate : location.state.checkOutDate);
	var timeDiff = Math.abs(date2.getTime() - date1.getTime());
	var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
	console.log('numberofnights', numberOfNights);
	const handleTBO = () => {
		let formData = {
			checkInDate: departDate ? departDate : location.state.checkInDate,
			checkOutDate: returnDate ? returnDate : location.state.checkOutDate,
			noOfNights: numberOfNights,
			countryName: hoteldes.country_name,
			countryCode: hoteldes.country_code,
			cityName: hoteldes.city_name,
			cityId: hoteldes.city_id,
			noOfRooms: room.length,
			guestNationality: 'IN',
			adultPax: adultH,
			childPax: childH,
			childAge: [...childAge1, ...childAge2, ...childAge3, ...childAge4],
			preferredCurrencyCode: 'INR',
			hotelCodeList: '0',
			starMaxRating: 5,
			startMinRating: 0,
		};
		// $1$pLx7Cf0C$JH0bormSXf/acY34KhBce1
		navigate('/hotelresult', { state: formData });
	};
	console.log('result', result);
	return (
		<>
			<Navbar />
			<MNavbar />

			<Box
				style={{
					// paddingLeft: matches ? '' : 80,
					// paddingRight: matches ? '' : 80,
					marginTop: matches ? 0 : 20,
				}}>
				<Container>
					<Grid container spacing={4}>
						{matches ? (
							''
						) : (
							<Grid item xs={12} lg={3}>
								<FilterSectionHotel setResult={setResult} />
							</Grid>
						)}
						<Grid item xs={12} lg={9}>
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								style={{
									backgroundColor: 'rgba(239, 241, 238, 1)',
									padding: 15,
									borderRadius: 5,
								}}>
								<Box>Sort By</Box>
							</Box>
							{loadinghotel ? (
								<>
									{Array.from(Array(10)).map((i) => {
										return <FlightRowSkeleton />;
									})}
								</>
							) : result?.length > 0 ? (
								''
							) : (
								hotel?.data?.Hotels?.length > 0 &&
								hotel?.data?.Hotels?.map((i) => {
									return (
										<Grid
											container
											style={{
												boxShadow: '0px 3.37px 12px 0px rgba(0, 0, 0, 0.15)',
												padding: 15,
												marginTop: 15,
												borderRadius: 5,
											}}>
											<Grid item xs={12} lg={8}>
												<Box
													display='flex'
													// justifyContent='space-between'
												>
													<Box>
														<img
															src={i.HotelPicture}
															style={{
																height: '250px',
																borderRadius: 15,
																width: '250px',
															}}
														/>
													</Box>
													<Box ml={2}>
														<h1
															style={{
																color: 'rgba(0, 102, 146, 1)',
																fontSize: 16,
																fontFamily: 'Outfit',
																fontWeight: 'bold',
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																width: '300px',
																height: '1.2em',
																whiteSpace: 'nowrap',
															}}>
															{i.HotelName}
														</h1>
														<Box display={'flex'} mt={1}>
															<img src={loc} />
															<p
																title={i.HotelAddress}
																style={{
																	color: 'rgba(0, 102, 146, 1)',
																	fontSize: 12,
																	fontFamily: 'Outfit',
																	fontWeight: 'bold',
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																	width: '300px',
																	height: '1.2em',
																	whiteSpace: 'nowrap',
																}}>
																{i.HotelAddress}
															</p>
														</Box>

														<Box display={'flex'} mt={1.5} alignItems='center'>
															<Box display={'flex'} alignItems={'center'}>
																<span
																	style={{
																		backgroundColor: 'rgba(0, 102, 146, 1)',
																		padding: 3,
																		fontSize: 10,
																		color: 'white',
																		borderRadius: 2,
																	}}>
																	{i.StarRating}
																</span>
																<p
																	style={{
																		color: 'rgba(0, 102, 146, 1)',
																		fontSize: 12,
																		marginLeft: 5,
																	}}>
																	Excellent
																</p>
															</Box>
															<p
																style={{
																	color: 'rgba(0, 102, 146, 1)',
																	fontSize: 12,
																	marginLeft: 5,
																}}>
																Rating
															</p>
														</Box>
														<Box display={'flex'} mt={1.5}>
															{/* <img src={tick} /> */}
															{/* <p
																	style={{
																		color: 'rgba(4, 167, 76, 1)',
																		fontSize: 12,
																		marginLeft: 5,
																	}}>
																	Breakfast Included
																</p> */}
														</Box>
														<Box display={'flex'}>
															{/* <img src={tick} /> */}
															{/* <p
																	style={{
																		color: 'rgba(4, 167, 76, 1)',
																		fontSize: 12,
																		marginLeft: 5,
																	}}>
																	Free Cancellation
																</p> */}
														</Box>
													</Box>
												</Box>
											</Grid>
											<Grid item xs={1.5}></Grid>
											<Grid item xs={12} lg={2.5}>
												<Box>
													{/* <Box
															style={{
																backgroundColor: 'rgba(252, 235, 235, 1)',
																padding: 8,
																alignItems: 'center',
																border: '0.5px solid rgba(255, 47, 47, 1)',
																borderRadius: 25,
																// width: '20%',
																marginTop: 20,
															}}>
															<p
																style={{
																	textAlign: 'center',
																	color: 'rgba(255, 47, 47, 1)',
																	fontSize: matches ? 8 : 12,
																}}>
																2 rooms left
															</p>
														</Box> */}
													<p
														style={{
															textAlign: 'end',
															marginTop: 10,
															textDecoration: 'line-through',
														}}>
														₹ {Number(Math.round(i.Total_Amount))}
													</p>
													<h1
														style={{
															textAlign: 'end',
															fontWeight: 'bold',
															fontSize: 20,
															marginTop: 10,
														}}>
														₹{' '}
														{Number(
															Math.round(
																Number(i.Total_Amount) - Number(i.MFB_Discount)
															)
														)}
													</h1>
													<p style={{ fontSize: 12, textAlign: 'end' }}>
														{/* + ₹3600 taxes & fees per night */}
													</p>
													<Button
														onClick={() =>
															navigate('/hotelreview', {
																state: {
																	item: i,
																	traceId:
																		hotelResult?.result?.HotelSearchResult
																			?.TraceId,
																},
															})
														}
														style={{
															backgroundColor: 'rgba(77, 165, 40, 1)',
															color: 'white',
															width: '100%',
															marginTop: 5,
														}}>
														Book
													</Button>
												</Box>
											</Grid>
										</Grid>
									);
								})
							)}
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default HotelResultPage;
