import React, { useState, useEffect } from 'react';
import { Button, Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModalEasebuzz } from '../../reducers/UiReducer';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import EaseBuzzForm from '../../forms/EaseBuzzForm';
const PaymentEaseBuzz = ({ pay, pgCharge, handleBook }) => {
	const [loading, setLoading] = useState(false);
	const { paymentToggleeasebuzz } = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	const handleOk = () => {
		// setLoading(true);
		// handleBook(setLoading);
	};
	// useEffect(() => {
	// 	// Dynamically load the Easebuzz script
	// 	const script = document.createElement('script');
	// 	script.src =
	// 		'https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js';
	// 	script.async = true;

	// 	script.onload = () => {
	// 		// Initialize the EasebuzzCheckout object
	// 		window.easebuzzCheckout = new window.EasebuzzCheckout(
	// 			'DO2J1JSRU2',
	// 			'prod'
	// 		); // "prod" or "test"
	// 	};

	// 	document.body.appendChild(script);

	// 	return () => {
	// 		// Cleanup: Remove the script from the document
	// 		document.body.removeChild(script);
	// 	};
	// }, []);
	const handleCancel = () => {
		dispatch(setModalEasebuzz(false));
	};

	return (
		<>
			<Modal
				open={paymentToggleeasebuzz}
				title='Confirm Booking'
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel} disabled={loading}>
						Cancel
					</Button>,
				]}>
				<Divider />
				<EaseBuzzForm pay={pay} pgCharge={pgCharge} handleBook={handleBook} />
			</Modal>
		</>
	);
};
export default PaymentEaseBuzz;
