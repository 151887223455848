import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setModalEasebuzzBus } from '../reducers/UiReducer';
const EaseBuzzFormBus = ({ pay, pgCharge, handleBook }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { depart, arrival } = useSelector((state) => state.ui);
	const { payment } = useSelector((state) => state.home);
	console.log('location', location);
	const handleSubmit = () => {
		// var options = {
		// 	key: 'rzp_test_2ZqKzJLqTKOQsY',
		// 	key_secret: 'tUHmb4gdwNB8AD7xkTSdMJme',
		// 	amount:
		// 		Number(
		// 			Number(
		// 				Math.round(
		// 					Number(
		// 						location.state.seat &&
		// 							location.state.seat?.length > 0 &&
		// 							location.state.seat
		// 								?.map((item) => parseInt(item.totalFare))
		// 								.reduce((prev, next) => prev + next)
		// 					) + Number(pgCharge)
		// 				)
		// 			)
		// 		) * 100,
		// 	currency: 'INR',
		// 	name: 'Mariners Forex',
		// 	description: 'for bus booking',
		// 	handler: function (response) {
		// 		// console.log('response', response);
		// 		// alert(response.razorpay_payment_id);
		// 		if (response.razorpay_payment_id) {
		// 			// setLoading(true);
		// 			dispatch(setModalEasebuzzBus(false));
		// 			handleBook();
		// 			// alert(response.razorpay_payment_id);
		// 			// dispatch(registerDispatch(form, navigate));
		// 			// dispatch(orderDispatch(form2, loginInfo?.token));
		// 		}
		// 	},
		// 	prefill: {
		// 		name: `${location.state.passengerData.adult[0].firstname} ${location.state.passengerData.adult[0].lastname}`,
		// 		email: location.state.passengerData.email,
		// 		contact: location.state.passengerData.phone,
		// 	},
		// 	notes: {
		// 		address: 'Razorpay Corporate office',
		// 	},
		// 	theme: {
		// 		color: '#3399cc',
		// 	},
		// };
		// var pay = new window.Razorpay(options);
		// pay.open();
		var easebuzzCheckout = new window.EasebuzzCheckout('DO2J1JSRU2', 'prod');
		var options = {
			access_key: payment?.data?.data, // access key received via Initiate Payment
			onResponse: (response) => {
				console.log(response);
				if (response?.status === 'userCancelled') {
					navigate('/');
				} else if (response.status === 'success') {
					// setLoading(true);
					dispatch(setModalEasebuzzBus(false));
					handleBook();
				} else {
					navigate('/');
				}
			},
			theme: '#123456', // color hex
		};
		easebuzzCheckout.initiatePayment(options);
	};

	return (
		<>
			<form
			// action='https://nitish.musafirbazar.com/pg/easebuzz/request.php'
			// method='POST'
			>
				<Grid container alignItems='center' style={{}}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='txnid'>OrderID</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input name='mrctTxtID' id='txnid' value={location.state.orderId} />
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='amount'>Amount</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='amount'
							id='amount'
							value={`${Number(
								Math.round(
									Number(
										location.state.seat &&
											location.state.seat?.length > 0 &&
											location.state.seat
												?.map((item) => parseInt(item.totalFare))
												.reduce((prev, next) => prev + next)
									) + Number(pgCharge)
								)
							)}.00`}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* <label for='surl'>returnURL</label> */}
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							hidden
							name='returnURL'
							id='surl'
							value='https://marinersforex.com/paymentpageBus'
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{}}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{/* <label for='furl'>failedURL</label> */}
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						{' '}
						<input
							hidden
							name='failedURL'
							id='furl'
							value='https://marinersforex.com/failed'
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='phone'>Mobile</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='phone'
							id='phone'
							value={location.state.passengerData.phone}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='email'>Email</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='email'
							id='email'
							value={location.state.passengerData.email}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='firstname'>Name</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input
							name='firstname'
							id='firstname'
							value={`${location.state.passengerData.adult[0].firstname} ${location.state.passengerData.adult[0].lastname}`}
						/>
					</Grid>
				</Grid>
				<Grid container alignItems='center' style={{ marginTop: 10 }}>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<label for='productinfo'>Productinfo</label>
					</Grid>
					<Grid item xs={6} style={{ textAlign: 'center' }}>
						<input name='productinfo' id='productinfo' value='Bus Booking' />
					</Grid>
				</Grid>

				<div style={{ marginTop: 30, textAlign: 'center' }}>
					<button
						type='button'
						onClick={handleSubmit}
						style={{
							cursor: 'pointer',
							background: 'green',
							padding: 10,
							color: 'white',
							borderRadius: 5,
						}}>
						Proceed to Payment{' '}
					</button>
				</div>
			</form>
		</>
	);
};

export default EaseBuzzFormBus;
